 

.navbar {
  display: none !important;
  
}

@media all and (min-width: 1024px) {
  .navbar {
    display: none;
  }
  .navbar__container {
    display: none;
  }
}
@media all and (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    display: none;
  }
  .navbar__container {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar {
    display: inline-block !important;
    position: sticky !important;
    top: 0;
    width: 100%;
    background: #000 !important;
    z-index: 100;
  }

  .navbar__container {
    display: flex;
    padding: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .navbar__logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }

  .navbar__toggle {
    font-size: 1.5rem;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
  }

  .navbar__menu {
    display: flex;
    list-style-type: none;
  }

  .navbar__item {
    margin-left: 1rem;
  }
  .navbar__item a {
    text-decoration: none;
    color: #fff;
  }
  .navbar__item a:hover {
    color: #2c67bf;
  }
  .navbar__link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .navbar__link:hover {
    color: #fff;
  }

  .navbar__toggle {
    display: block;
  }

  .navbar__menu {
    display: none;
    flex-direction: column;
    padding-left: 0;
    margin-top: 1rem;
  }

  .navbar__menu.mobile {
    display: flex;
  }

  .navbar__item {
    margin: 0.5rem 0;
  }

  .navbar__menu {
    /* Set initial values */
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .navbar.mobile .navbar__menu {
    /* Animate the menu when in mobile mode */
    opacity: 1;
    transform: translateY(0);
  }
}
