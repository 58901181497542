h2{
    font-family: 'Roboto Condensed', sans-serif;;
    font-weight: bold !important;
}
.badge {
    font-family: 'Poppins', sans-serif  ;
     
}
.detail{
    font-family: 'Poppins', sans-serif;
}
 