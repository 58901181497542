.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container_wrap {
  height: 100%;
  cursor: pointer;
}
.text {
  color: antiquewhite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.image_hover {
  object-fit: cover;
  width: 100%;
  height: 100%;

  -webkit-filter: blur(2px);
  filter: blur(2px);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  transition: all 0.3s ease-in-out;
  border-radius: 1rem;
}

.works {
  display: grid;
  width: 90%;
  grid-template-rows: 10% auto;
  margin: 0 auto;
}
.menu {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
  align-items: center;
}
.menu > li {
  cursor: pointer;
}
.menu > li:hover {
  color: var(--secondary-color);
}
.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  height: auto;
  grid-gap: 1rem;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow-y: scroll;
}
.work {
  width: 100%;
  height: 250px;

  position: relative;

  padding: 0.4rem;
  overflow: hidden;
  border-radius: 1rem;
}
.work > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.workdetails {
  position: absolute;
  top: 5px;
}

@media screen and (max-width: 856px) {
  .works {
    display: grid;
    width: 90%;
    grid-template-rows: 10% auto;
    margin: 0 auto;
  }
  .menu {
    flex-direction: row;
  }
}

@media screen and (max-width: 640px) {
  .menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  .menu {
    gap: 0.5rem;
    font-size: 1rem;
    margin-left: -4rem;
  }
  .container h1 {
    font-size: 1.5rem;
  }
  .works {
    grid-template-rows: 120px auto;
  }
}
