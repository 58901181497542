.formgroup {
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif !important;
}

.formRow {
  display: flex;
  justify-content: space-between;
}

.col {
  width: 100%;
  background-color: #fff;
  color: #333;
}
.col:first-child {
  margin-right: 5px;
}

.btn {
  background-color: #333;
  color: #fff;
  font-size: 16px;
  border-radius: 2px;
  padding: 10px 20px;
  cursor: pointer;
}
.btnsubmit {
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 5%;
  background-color: #00bcd4;
  color: #fff;
  margin-top: 10px;
  font-family: "Poppins", sans-serif !important;
}

/* Custom styling */
.upperFormcontrol {
  width: 100%;
  background-color: #fff;
  color: #333;
  border-radius: 2px;
  border: 1px solid #ccc;
  padding: 10px;
}
.formControl {
  border-radius: 2px;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  color: #333;
  width: 100%;
  height: 120px;

  font-family: "Poppins", sans-serif !important;
}

@media (max-width: 768px) {
  .socialicons {
    display: flex;
    font-size: 20px;
    width: 100% !important;
    justify-content: space-evenly !important;
  }
}
