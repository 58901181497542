.main {
  margin-left: 250px;
  width: calc(100% - 250px); /* Calculate the width of the main content */
}
._2qp0Z{
background : #5ad167 !important;
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .main {
    margin-left: 0px;
    width: 100%;
  }
}

@media all and (max-width: 480px) {
  .main {
    margin-left: 0px;
    width: 100%;
  }
}
