.infowrapper {
  position: relative;
  height: 100vh;
  background-color: #00000010;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  z-index: 1 !important;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.infos {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-45%, -45%);
  text-align: center;
  width: 80%;
  color: var(--light-color);
}
.infos h1 {
  font-family: "Poppins", sans-serif;

  font-size: 4rem !important;
  margin: auto;
}
.infos h2 {
  font-size: 2rem;
  margin: 15px 0;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.infos p {
  font-size: 1.3rem;
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
  color: gray;
}
.infos a {
  margin-top: 12px !important;
  font-family: "Poppins", sans-serif;
  width: 120px;
  color: #2c67bf;
  font-size: 1.3rem;
  margin: 0 auto;
  padding: 10px 20px;
}

.scroll_down_arrow {
  position: absolute;
  bottom: 20px;
  left: 48%;
  transform: translateX(-48%);
  animation: scroll-down-animation 1.5s infinite;
}

.arrow_icon {
  font-size: 32px;
  color: var(--light-color);
}

@keyframes scroll-down-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.cashbg {
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .infowrapper {
    position: relative;
    height: 83vh;
  }
}
