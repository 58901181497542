.featuredbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  font-family: "Roboto Condensed", sans-serif !important;

  margin: 10px;
  padding: 15px;
}
.featuredbox h3 {
  font-weight: 400;
}
.iconbox {
  padding: 15px;
  margin: 7px;

  background-color: #fff;
  color: var(--secondary-color);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  border-radius: 10%;
}

.infobox {
  margin-left: 10px;
  flex-direction: column;
}
.infobox p {
  color: var(--bs-gray-700);
}
