.vertical_header {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--dark-color);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px; /* Width of the vertical header */
  z-index: 999;

  align-items: center;
  text-align: center;
  overflow-y: auto;
}

.logo {
  margin: 20px;
}
.logo img {
  object-fit: cover;
  height: 140px;
  width: 140px;
  margin: 10px auto;
  background-size: cover;

  border-radius: 100%;

  border: 5px solid #343a40;
}
.menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 15px 0;
}

h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px !important;
  font-weight: 800 !important;
}
.menu a {
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
}

.menu a:hover {
  color: var(--primary-color);
}
.sidebar_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding: 1rem;
}

.social_icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 40px;
  font-size: 1.2rem;
  color: var(--light-color);
  transition: all 0.3s ease;
}

.social_icon:hover {
  transform: translateY(-2px);
  color: var(--secondary-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


@media all and (min-width: 480px) and (max-width: 768px) {
  .vertical_header {
    display: none;
  }
}

@media all and (max-width: 480px) {
  .vertical_header {
    display: none;
  }
}
