@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@300;400;900&family=Roboto+Condensed:wght@300;400;800&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: #212529;
  font-family: "Poppins", sans-serif;
  /* overflow-x: hidden; */
}
 
* {
  font-family: "Poppins", sans-serif;
  
}
.greenIcon {
  filter: hue-rotate(120deg); /* Rotate hue to make icon green */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.typing-text {
  overflow: hidden;
  white-space: nowrap;
  transition: height 0.2s ease-in-out;
}

.skillbar-percent {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #000 !important;
}
.skillbar-title {
  font-family: "Open Sans", sans-serif;
}
.skillbar {
  margin-bottom: 25px !important;
}

.btn-outline-secondary {
  font-family: "Open Sans", sans-serif !important;
  margin: 20px 0;
  padding: 10px 8px !important;
  font-weight: bold !important;
}

.modal {
  background-color: #21252994 !important;
}

.slick-dots li.slick-active button:before {
  color: var(--primary-color) !important;
  font-size: 10px;
}
.active {
  color: var(--primary-color) !important;
}
