.description {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  line-height: 1.7;
}
.infowrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2.5;
  align-items: center;
}
.info li {
  list-style: none;
  border-bottom: 1px solid #eaeaea;
}
.firstchild {
  font-weight: 900;
  font-size: 17px;
  color: #4c4d4d;
}
.info a {
  text-decoration: none;
}
.row {
  font-family: "Poppins", sans-serif;
}
.row div {
  border-right: 1px dotted #595c5f;
}
.row div:last-child {
  border-right: none;
}
.row h4 {
  font-size: 3rem;
  font-family: "Poppins", sans-serif;
  color: var(--dark-color);
}
.imgMobile {
  display: none;
}

@media (max-width: 768px) {
  .row div {
    border-right: none;
  }
  .imgMobile {
    display: block;
  }
  .imgMobile img {
    object-fit: cover;
    height: 140px;
    width: 140px;
    margin: 10px auto;
    background-size: cover;

    border-radius: 100%;

    border: 5px solid #343a40;
  }

  .container {
    width: 100%;
    max-width: 500px;
  }
  .firstchild {
    font-size: 1.1rem !important;
  }
  .description,
  .infowrap {
    flex-direction: column;
  }

  .info {
    padding: 0 10px;
  }

  .brands-grid {
    margin-top: 0;
  }

  .featured-box {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100%;
    max-width: 320px;
  }
  .firstchild {
    font-size: 1.1rem !important;
  }

  .description {
    padding: 0 15px;
  }

  .info {
    padding: 0 10px;
  }

  .brands-grid {
    margin-top: 0;
  }

  .featured-box {
    width: 100%;
  }
}
